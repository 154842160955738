<template>
  <div>
    <h3 class="ls-center">Create new Runner Request</h3>
    <form v-if="auth.isAuthenticated">
      <div class="form-group">
        <label for="select_cat">Select Category</label>
        <select class="form-control" id="select_cat" v-model="runnerRequest.runner_category_id">
          <option v-for="runnerCategory in runnerCategories" :key="runnerCategory.id" :value="runnerCategory.id">
            {{ runnerCategory.name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="catDescription">Title</label>
        <input class="form-control" id="catTitle" v-model="runnerRequest.title" />
      </div>
      <div class="form-group">
        <label for="catDescription">Description</label>
        <textarea class="form-control" id="catDescription" rows="3" v-model="runnerRequest.description"></textarea>
      </div>
      <div class="form-group">
        <label for="catDescription">Phone number of Contact Person</label>
        <input class="form-control" id="catDescription" type="number" v-model="runnerRequest.contact" />
      </div>
      <div class="form-group">
        <label for="catDescription">Due Date</label>
        <input class="form-control" id="catDate" type="date" data-date-format="mm/dd/yyyy"
          v-model="runnerRequest.due_date" />
      </div>

      <button v-if="auth.isAuthenticated" :disabled="isLoading" type="submit" class="btn btn-primary ls-submit-button"
        @click.prevent="onCreateRunnerRequest()">
        {{ isLoading ? "Processing..." : "Submit" }}
        <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
        <div v-if="isLoading" class="spinner-border text-light" role="status"></div>
      </button>
      <router-link v-else :to="{ name: 'login' }">
        <button type="submit" class="btn btn-primary ls-submit-button">
          Submit
        </button>
      </router-link>
    </form>
    <div v-else class="ls-center mb-4 mt-4">
      <p>Please login or create your myPahukama account to be able to create a new runner request or to visit your runner
        request dashboard.</p>
      <div class="align-items-center justify-content-between mb-4 mt-4">
        <div>
          <button @click="createRequest()" class="ps-btn btn-sm m-3">
            Create Request</button>
          <button @click="myRequests()" class="ps-btn btn-sm m-3">
            MyRequests</button>
          <button @click="myRequests()" class="ps-btn btn-sm m-3">
            Requests Inbox</button>
        </div>
      </div>
      <h4>Pahukama Avalable Runner/Errands Services </h4>
      <ul class="text-left">
        <li>Groceries and any shopping</li>
        <li>Beers and Liquir Corner</li>
        <li>Bread and Fresh Bakery Things</li>
        <li>Broadband and DSTV</li>
        <li>Back to School & Education Things</li>
        <li>Healthcare & Pick/Drop Medications</li>
        <li>Any Zim Bills and Fees Payments</li>
        <li>Home Improvements</li>
        <li>Organize Treate and Special events</li>
        <li>Boreholes drillind and securing</li>
        <li>Home Solar Systems</li>
        <li>Accounting Services - Company registration and taxes</li>
        <li>Legal services - title deeds and property transfer</li>
        <li>Special Events - Birthda, Wedding and Surprises</li>
        <li>Property and Tenant Management</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "Runner",
  data() {
    return {
      runnerCategories: [],
      runnerRequest: {
        title: "",
        description: "",
        runner_category_id: "",
        contact: "",
        due_date: "",
      },
      isLoading: false,
    };
  },
  mounted() {
    this.getRunnersDetails();
    if (this.auth.isAuthenticated) {
      this.runnerRequest.contact = this.auth.user.phone;
    }
    $("#catDate").datepicker({
      weekStart: 1,
      daysOfWeekHighlighted: "6,0",
      autoclose: true,
      todayHighlight: true,
    });
    $("#catDate").datepicker("setDate", new Date());
    this.runnerRequest.due_date = new Date().toLocaleDateString("en-US");
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  methods: {
    getRunnersDetails() {
      axios.get("runners/categories").then((response) => {
        //console.log("Get Runner Cat : " + JSON.stringify(response.data));
        this.runnerCategories = response.data;
      });
    },
    onCreateRunnerRequest() {
      //the request method to save the request
      let data = {
        category_id: this.runnerRequest.runner_category_id,
        body: this.runnerRequest.description,
        title: this.runnerRequest.title,
      };

      this.isLoading = true;
      axios
        .post("runners/requests", data)
        .then((response) => {
          this.isLoading = false;
          if (response.status === 200 || response.status === 201) {
            this.message = "User successfully created";

            this.$toasted.show("Request successfully created. ");
            this.runnerRequest.description = "";
            this.runnerRequest.runner_category_id = "";
            location.href = "/runners/requests/view/" + response.data.id;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toasted.error("Saving failed, please try again");
        });
    },
    createRequest() {
      this.$store.commit("auth/next", "runner_requests");
      this.$router.push({ name: "login" });
    },
    myRequests() {
      this.$store.commit("auth/next", "my_runner_requests");
      this.$router.push({ name: "login" });
    },
  },
};
</script>
